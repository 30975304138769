import { ArrowRightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip, Typography } from 'antd';
import { useMemo } from 'react';
import styled from 'styled-components';

import { CustomerData } from '../../types/customer';
import { dateUtils } from '../../utils/dates';
import { CustomCard, CustomLinkCard } from '../CustomCard';
import { EmptyState } from '../EmptyState';

type CardsSectionProps = {
  customerData: CustomerData;
  onClickPaymentsCard: () => void;
  onClickClassOnMonth: () => void;
};

const { Text, Title } = Typography;

export const CardsSection: React.FC<CardsSectionProps> = ({
  customerData,
  onClickPaymentsCard,
  onClickClassOnMonth,
}) => {
  const { additionalInfo } = customerData.customer;

  const { lastProduct, countProgramsWatched, gatewayOrigin, lifeCycle } =
    additionalInfo || {};

  const tooltipContent = useMemo(
    () => (
      <StyledTooltipContent>
        <p>
          <strong>Nível de Engajamento do Usuário:</strong>
        </p>
        <ul>
          <li>
            <strong>Onboarding:</strong> 0 dias de aula e 0 aulas.
          </li>
          <li>
            <strong>Ativação:</strong> 1 dia de aula e pelo menos 1 aula.
          </li>
          <li>
            <strong>Adaptação:</strong> Pelo menos 2 dias de aula e pelo menos 2
            aulas.
          </li>
          <li>
            <strong>Engajamento:</strong> Pelo menos 5 dias de aula e pelo menos
            10 aulas
          </li>
          <li>
            <strong>Hábito:</strong> Pelo menos 15 dias de aula
          </li>
        </ul>
      </StyledTooltipContent>
    ),
    []
  );

  return (
    <Row gutter={24} justify='space-between' align={'top'}>
      <Col md={{ span: 6 }} lg={{ span: 10 }}>
        {lastProduct?.name && lastProduct?.purchaseDate ? (
          <CustomCard
            title='Pagamentos'
            extra={
              <CustomLinkCard onClick={() => onClickPaymentsCard()}>
                Ver mais <ArrowRightOutlined />
              </CustomLinkCard>
            }
          >
            <Row justify='space-between' align={'middle'}>
              <Text type='secondary'>Ultimo produto adiquirido</Text>
              <Text type='secondary'>Data</Text>
            </Row>
            <Row justify='space-between' align={'middle'}>
              <Title level={4} style={{ margin: 0 }}>
                {lastProduct.name}
              </Title>
              <Text>
                {dateUtils.formatDateString(
                  lastProduct.purchaseDate,
                  'dd/MM/yyyy'
                )}
              </Text>
            </Row>
          </CustomCard>
        ) : (
          <CustomCard>
            <EmptyState
              title='Sem pagamentos realizados no momento'
              subtitle='O usuário selecionado não possui uma assinatura.'
            />
          </CustomCard>
        )}
      </Col>

      <Col md={{ span: 6 }} lg={{ span: 6 }}>
        {countProgramsWatched?.lastClassDate ? (
          <CustomCard
            title='Aulas no mês'
            extra={
              <CustomLinkCard onClick={() => onClickClassOnMonth()}>
                Ver mais <ArrowRightOutlined />
              </CustomLinkCard>
            }
          >
            <Row justify='space-between' align={'middle'}>
              <Text type='secondary'>
                {`Última aula em 
                ${dateUtils.formatDateString(
                  countProgramsWatched.lastClassDate,
                  'dd/MM/yyyy'
                )}`}
              </Text>
            </Row>
            <Row justify='space-between' align={'middle'}>
              <Title level={4} style={{ margin: 0 }}>
                {countProgramsWatched.watchedOnMonth ?? 0} aulas
              </Title>
              <Text>últimos 30 dias</Text>
            </Row>
          </CustomCard>
        ) : (
          <CustomCard>
            <EmptyState
              title='Sem aulas realizadas'
              subtitle='Usuário sem aulas nos últimos 30 dias.'
            />
          </CustomCard>
        )}
      </Col>

      <Col md={{ span: 6 }} lg={{ span: 4 }}>
        {gatewayOrigin?.name && gatewayOrigin.startDate ? (
          <CustomCard title='Origem'>
            <Text type='secondary'>
              {`Início em 
              ${dateUtils.formatDateString(
                gatewayOrigin.startDate,
                'dd/MM/yyyy'
              )}`}
            </Text>
            <Title level={4} style={{ margin: 0 }}>
              {gatewayOrigin.name}
            </Title>
          </CustomCard>
        ) : (
          <CustomCard>
            <EmptyState
              title='Sem origem'
              subtitle='Origem não especificada.'
            />
          </CustomCard>
        )}
      </Col>

      <Col md={{ span: 6 }} lg={{ span: 4 }}>
        {lifeCycle?.name ? (
          <CustomCard
            title='Ciclo de vida M1'
            extra={
              <Tooltip
                placement='leftTop'
                title={tooltipContent}
                overlayInnerStyle={{
                  marginTop: '-8px',
                }}
              >
                <QuestionCircleOutlined style={{ marginLeft: 8 }} />
              </Tooltip>
            }
          >
            <Text type='secondary'>{`${
              lifeCycle.totalWatchedCount ?? 0
            } aulas assistidas`}</Text>
            <Title level={4} style={{ margin: 0 }}>
              {lifeCycle.name}
            </Title>
          </CustomCard>
        ) : (
          <CustomCard>
            <EmptyState
              title='Ciclo indefinido'
              subtitle='Ciclo não especificado'
            />
          </CustomCard>
        )}
      </Col>
    </Row>
  );
};

const StyledTooltipContent = styled.div`
  > p {
    padding: 10px;
  }

  > ul {
    padding-inline-start: 28px;

    li {
      padding-right: 20px;
      padding-bottom: 10px;
    }
  }
`;
