import { FundOutlined, UserOutlined } from '@ant-design/icons';
import { slices } from '@core/redux';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { TabComponentProps } from 'src/app/components/antd/TabComponent';

import { customerApi } from '../api/customerApi';

const CUSTOMER_TABS_OPTIONS: TabComponentProps['tabList'] = [
  { title: 'Resumo do Cliente', icon: <UserOutlined /> },
  // { title: 'Comunicações', icon: <MailOutlined /> }, // temporary
  { title: 'Uso e engajamento', icon: <FundOutlined /> },
];

interface CustomerParams {
  id: string;
}

export const useCustomerDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [tabActive, setTabActive] = useState<string>('0');

  const { id } = useParams<CustomerParams>();
  const [searchParam, setSearchParam] = useState(id);
  const {
    data: customerData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['customer', searchParam],
    queryFn: () => customerApi.fetchCustomerData(searchParam),
    enabled: !!searchParam,
    refetchOnWindowFocus: false,
  });
  const [customerExists, setCustomerExists] = useState(false);

  const replaceUrlCustomerId = (newId: string) => {
    history.replace(`${newId}`, { shallow: true });
  };

  const handleSearchCustomer = (newSearchParam: string) => {
    setSearchParam(newSearchParam);
  };

  useEffect(() => {
    if (!customerData && !isLoading) {
      setCustomerExists(false);
      dispatch(
        slices.layout.updateBreadcrumbs([
          {
            label: 'Clientes',
            route: '/clientes',
          },
          { label: 'Cliente não encontrado' },
        ])
      );
    }

    if (customerData) {
      const { customer } = customerData;
      replaceUrlCustomerId(customer.id);
      setCustomerExists(true);
      dispatch(
        slices.layout.updateBreadcrumbs([
          {
            label: 'Clientes',
            route: '/clientes',
          },
          { label: customer.name },
        ])
      );
    }
  }, [customerData, isLoading]);

  return {
    tabActive,
    setTabActive,
    tabList: CUSTOMER_TABS_OPTIONS,
    customerData,
    customerExists,
    handleSearchCustomer,
    isLoading,
    isError,
  };
};
