import { baseClient } from 'src/app/baseClient';

import {
  CustomerActiveDays,
  CustomerData,
  CustomerMostWatchedInstructor,
  CustomerMostWatchedProgram,
  CustomerSearch,
  CustomerSubscriptions,
  CustomerUpdateParams,
  CustomerWatchedClassesByDevice,
  Recommendation,
  UsageEngagement,
} from '../types/customer';

export interface WithPagination<T> {
  count: number;
  rows: T;
}

async function fetchCustomerUsageEngagement(
  customerId: string
): Promise<UsageEngagement> {
  const endpoint = `/customer/${customerId}/usage-engagement`;

  const { data } = await baseClient.get<UsageEngagement>(endpoint);
  return data;
}

async function fetchCustomerData(searchParam: string): Promise<CustomerData> {
  const endpoint = `/customer/${searchParam}`;

  const { data } = await baseClient.get<CustomerData>(endpoint);
  return data;
}

async function fetchCustomerSubscriptions(
  customerId: string,
  page: number,
  limit: number
): Promise<WithPagination<CustomerSubscriptions[]>> {
  const endpoint = `/customer/${customerId}/subscriptions?page=${page}&limit=${limit}`;

  const { data } = await baseClient.get<
    WithPagination<CustomerSubscriptions[]>
  >(endpoint);

  return data;
}

async function fetchCustomerActiveDays(
  customerId: string,
  startDate: string,
  endDate: string
) {
  const endpoint = `/customer/${customerId}/engagement/active-days?startDate=${startDate}&endDate=${endDate}`;

  const { data } = await baseClient.get<CustomerActiveDays>(endpoint);
  return data;
}

async function fetchCustomerMostWatchedPrograms(
  customerId: string,
  startDate: string,
  endDate: string
) {
  const endpoint = `/customer/${customerId}/engagement/most-watched-programs?startDate=${startDate}&endDate=${endDate}`;

  const { data } = await baseClient.get<CustomerMostWatchedProgram[]>(endpoint);
  return data;
}

async function fetchCustomerMostWatchedInstructors(
  customerId: string,
  startDate: string,
  endDate: string
) {
  const endpoint = `/customer/${customerId}/engagement/most-watched-instructors?startDate=${startDate}&endDate=${endDate}`;

  const { data } = await baseClient.get<CustomerMostWatchedInstructor[]>(
    endpoint
  );
  return data;
}

async function fetchCustomerWatchedClassesByDevices(
  customerId: string,
  startDate: string,
  endDate: string
) {
  const endpoint = `/customer/${customerId}/engagement/watched-classes/devices?startDate=${startDate}&endDate=${endDate}`;

  const { data } = await baseClient.get<CustomerWatchedClassesByDevice[]>(
    endpoint
  );
  return data;
}

async function fetchCustomerRecommendations(
  customerId: string,
  startDate: string,
  endDate: string,
  page: number,
  limit: number
) {
  const endpoint = `/customer/${customerId}/engagement/recommendations?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;

  const { data } = await baseClient.get<WithPagination<Recommendation[]>>(
    endpoint
  );
  return data;
}

async function fetchCustomerSearches(
  customerId: string,
  startDate: string,
  endDate: string,
  page: number,
  limit: number
) {
  const endpoint = `/customer/${customerId}/engagement/searches?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;

  const { data } = await baseClient.get<WithPagination<CustomerSearch[]>>(
    endpoint
  );
  return data;
}

async function updateCustomer(params: CustomerUpdateParams) {
  const { id, ...payload } = params;
  const endpoint = `/customer/${id}`;
  return await baseClient.put(endpoint, payload);
}

export const customerApi = {
  fetchCustomerData,
  fetchCustomerSubscriptions,
  fetchCustomerUsageEngagement,
  fetchCustomerActiveDays,
  fetchCustomerMostWatchedPrograms,
  fetchCustomerMostWatchedInstructors,
  fetchCustomerWatchedClassesByDevices,
  fetchCustomerRecommendations,
  fetchCustomerSearches,
  updateCustomer,
};
